<template>
  <div>
    <!-- <Tips/> -->
    <van-nav-bar class="question-title" fixed left-arrow>
      <template #left>
        <van-button color="#ffffff" @click="handleClickProject">{{groupName}}
          <van-icon name="play"/>
        </van-button>
      </template>
      <template #right>
        <van-grid>
          <!-- <van-grid-item icon="search" text="搜索" @click="handleClickSearch"/> -->
          <van-grid-item icon="chat-o" text="客服" @click="mySchool"/>
        </van-grid>
      </template>
    </van-nav-bar>
    <transition name="van-slide-right" mode="out-in">
      <router-view></router-view>
    </transition>

    <div class="question-content">
      <div class="banner-index">
        <van-swipe class="my-swipe" :autoplay="3000" :height="120" indicator-color="#1989fa">
          <van-swipe-item v-for="item in banner" :key="item.id" class="swipe_item">
            <img :src="item.image_url" width="100%" style="display: block;" alt/>
          </van-swipe-item>
        </van-swipe>
      </div>
      <div class="question-navigation">
        <van-grid :column-num="5">
          <van-grid-item v-for="(item, index) in questionNavigation" :key="index" :text="item.text"
                         :icon="item.photo" @click="handler2(item.link)"/>
          {{ item }}
        </van-grid>
      </div>
      <div class="question-type">
        <van-grid :column-num="2" direction="horizontal">
          <van-grid-item v-for="(item, index) in questionCateList" :key="index">
            <template #default>
              <van-tag v-if="item.id==4" type="warning" class="tagLabel">全新升级</van-tag>
              <van-tag v-if="item.id==7" type="warning" class="tagLabel">视频讲解</van-tag>
              <van-cell class="typeList" center @click="handler(item.id,item.title)">
                <template #title>
                  <span class="custom-title">{{item.title}}</span>
                  <van-tag round type="warning" v-show="item.show">{{item.tagText}}</van-tag>
                </template>
                <template #right-icon>
                  <van-icon :name="item.web_icon"/>
                </template>
                <template #label>
                  <span class="label-title">{{item.desc}}</span>
                </template>
              </van-cell>
            </template>
          </van-grid-item>
          {{ item }}
        </van-grid>
      </div>
      <div class="question-product">
        <van-cell center title="推荐课程" value="更多" is-link class="title" @click="courseMore"/>
        <div class="shoplist" v-for="(item,index) in commodityList" :key="index">
          <van-card
                  class="shop-card"
                  :tag="item.discount"
                  :desc="item.commodity_content"
                  :title="item.title"
                  :thumb="item.photopath"
                  @click="handleClickCourseDetail(item.detail_id)"
          >
            <template #tags>
              <div class="tags">
                <van-tag plain round type="warning" v-for="item2 in item.label">{{item2}}</van-tag>
              </div>
            </template>
            <template #bottom>
              <!--                            <span class="price">￥{{item.goods_price}}</span>-->
              <van-button round type="danger" size="mini" class="audition">试听报名</van-button>
            </template>
          </van-card>
        </div>
        {{ item }}
      </div>
    </div>


  </div>
</template>

<script>
  import {questionIndexData, getCommodityQuestionList} from '@/request/api'
  import {Toast} from "vant";

  export default {
    name: 'Question',
    metaInfo: {
      title: '题库', //设置页面title
      meta: [{                 //设置页面meta
        name: 'keyWords',
        content: '关键词'
      },
        {
          name: "description",
          content: "描述",
        },
      ]
    },
    data() {
      return {

        show: false,
        item: "",
        //轮播图数组
        banner: [
          {
            "id": 1,
            "ad_position_id": 1,
            "media_type": 1,
            "name": "名师好题",
            "link": "",
            "image_url": "http://cdnoss.ksyt.com.cn/wxbanner/20221228/1ceaf3a5c103bbce99d85de87c70ac49.png",
            "content": "名师好题",
            "end_time": 0,
            "enabled": 1
          },
          {
            "id": 2,
            "ad_position_id": 1,
            "media_type": 1,
            "name": "海量题库",
            "link": "",
            "image_url": "http://cdnoss.ksyt.com.cn/wxbanner/20221228/fb421a795becb9902803417fe936ee98.png",
            "content": "海量题库",
            "end_time": 0,
            "enabled": 1
          }
        ],

        questionNavigation: [
          {
            text: "做题记录",
            icon: "n",
            photo: "http://cdnoss.ksyt.com.cn/wxImages/assets/images/icon_jilu.png",
            link: 'QuestionRecord'

          },
          {
            text: "错题集",
            icon: "w",
            photo: "http://cdnoss.ksyt.com.cn/wxImages/assets/images/icon_cuoti.png",
            link: 'QuestionErrorSet'
          },
          {
            text: "收藏夹",
            icon: "j",
            photo: "http://cdnoss.ksyt.com.cn/wxImages/assets/images/icon_shoucang.png",
            link: 'QuestionCollection'
          },
          {
            text: "题库答疑",
            icon: "g",
            photo: "http://cdnoss.ksyt.com.cn/wxImages/assets/images/icon_dayi.png",
            link: 'QuestionAnswer'
          },
          {
            text: "做题报告",
            icon: "h",
            photo: "http://cdnoss.ksyt.com.cn/wxImages/assets/images/icon_baogao.png",
            link: 'QuestionDayEcharts'
          },
        ],

        productList: [
          {
            goods_tag: "推荐",
            goods_title: "2022年中药全科（模考班）",
            goods_desc: "导学班+精讲班+强化班+真题班+习题班+点睛班",
            goods_thumb: "http://cdnoss.ksyt.com.cn/weixin/banci.png",
            goods_price: 980.00
          },
          {
            goods_tag: "推荐",
            goods_title: "2022年西药全科（模考班）",
            goods_desc: "导学班+精讲班+强化班+真题班+习题班+点睛班",
            goods_thumb: "http://cdnoss.ksyt.com.cn/weixin/banci.png",
            goods_price: 980.00
          }
        ],
        groupName: (localStorage.getItem("groupName") ? localStorage.getItem("groupName") : '未选择'),
        bannerList:[],
        questionCateList: [],
        commodityList: []
      }
    },
    created() {
      this.getData();
    },
    mounted() {

    },
    methods: {
      mySchool(){
        this.$router.push('/MySchool')
      },
      handleClickProject() {
        this.$router.push({
          name: 'Project',
          query: {
            redirect: (this.$route.name)
          }
        })
        // 修改vuex中的 isShowPopupShow 值为true
        // this.$store.commit("show", true)
        // console.log("搜索按钮被点击");
      },

      handleClickSearch() {
        this.$router.push('/question/searchPopup')
        // 修改vuex中的 isShowPopupShow 值为true
        // this.$store.commit("changeIsShowPopupShow", true)
        // console.log("搜索按钮被点击");
      },

      courseMore() {
        // this.$router.push('/course')
        this.$router.push({
          name: 'CourseApp',
          params:{
            appOrH5:false
          },
          query:{
            appOrH5:false
          }
        })
        // 修改vuex中的 isShowPopupShow 值为true
        // this.$store.commit("changeIsShowPopupShow", true)
        // console.log("搜索按钮被点击");
      },
      // handleClickCourseDetail() {
      //   this.$router.push('/courseDetail')
      //   // 修改vuex中的 isShowPopupShow 值为true
      //   // this.$store.commit("changeIsShowPopupShow", true)
      //   // console.log("搜索按钮被点击");
      // },
      handler(id,title) {
        let url = '';
        if(id == 1) {
          //历年真题
          url = 'QuestionList'
        } else if(id == 2) {
          //模考试卷
          url = 'QuestionList';
        } else if(id == 3) {
          //海量题库
          url = 'QuestionListK';
        } else if(id == 4) {
          //每日一练
          url = 'QuestionDailyPracticeList';
        } else if(id == 7) {
          //名师好题
          url = 'QuestionListK';
        } else if(id == 8) {
          //考前冲刺
          url = 'QuestionList';
        } else if(id == 9) {
          //通关白宝卷
          url = '';
        } else {
          //其他
          Toast('暂未开放');
          return false;
        }
        this.$router.push({
          name: url,
          params:{
            id,
            title:title,
            appOrH5:false
          },
          query:{
            id,
            title:title,
            appOrH5:false
          }
        })
      },
      handler2(link) {
        this[link]()
      },
      QuestionRecord(){
        // this.$router.push('/QuestionRecord')
        this.$router.push({
          name: 'questionRecord',
          params:{
            appOrH5:false
          },
          query:{
            appOrH5:false
          }
        })
      },
      QuestionErrorSet(){
        // this.$router.push('/QuestionErrorSet')
        this.$router.push({
          name: 'questionErrorSet',
          params:{
            appOrH5:false
          },
          query:{
            appOrH5:false
          }
        })
      },
      QuestionCollection(){
        // this.$router.push('/QuestionCollection')
        this.$router.push({
          name: 'questionCollection',
          params:{
            appOrH5:false
          },
          query:{
            appOrH5:false
          }
        })
      },
      QuestionDayEcharts(){
        // this.$router.push('/QuestionDayEcharts')
        this.$router.push({
          name: 'questionDayEcharts',
          params:{
            appOrH5:false
          },
          query:{
            appOrH5:false
          }
        })
      },
      QuestionAnswer() {
        // this.$router.push('/QuestionAnswer')
        this.$router.push({
          name: 'questionAnswer',
          params:{
            appOrH5:false
          },
          query:{
            appOrH5:false
          }
        })
      },
      handleClickType() {
        // this.$router.push('/questionList')
        this.$router.push({
          name: 'QuestionList',
          params:{
            appOrH5:false
          },
          query:{
            appOrH5:false
          }
        })
        // 修改vuex中的 isShowPopupShow 值为true
        // this.$store.commit("changeIsShowPopupShow", true)
        // console.log("搜索按钮被点击");
      },
      handleClickExam() {
        // this.$router.push('/Zxks')
        this.$router.push({
          name: 'Zxks',
          params:{
            appOrH5:false
          },
          query:{
            appOrH5:false
          }
        })
        // 修改vuex中的 isShowPopupShow 值为true
        // this.$store.commit("changeIsShowPopupShow", true)
        // console.log("搜索按钮被点击");
      },
      handleCalculator() {
        this.$router.push('/calculator')
        // 修改vuex中的 isShowPopupShow 值为true
        // this.$store.commit("changeIsShowPopupShow", true)
        // console.log("搜索按钮被点击");
      },

      getData() {
        let that = this;
        questionIndexData()
                .then(res => {
                  if (res.data.code == 1) {
                    that.getCommodityData();
                    that.bannerList = res.data.data.banner
                    that.questionCateList = res.data.data.data
                    localStorage.setItem("groupName",res.data.data.groupData.groupName);
                    localStorage.setItem("groupId",res.data.data.groupData.select_group);
                    localStorage.setItem("subjectId",res.data.data.groupData.select_subject);
                  }
                })
                .catch(err => {
                  console.log("error");
                  console.log(err);
                })
      },
      getCommodityData() {
        let that = this;
        getCommodityQuestionList()
                .then(res => {
                  if (res.data.code == 1) {
                    that.commodityList = res.data.data
                  }
                })
                .catch(err => {
                  console.log("error");
                  console.log(err);
                })
      },
      handleClickCourseDetail(id) {
        if(this.getToken()) {
          this.$router.push({
            name: 'CourseDetail',
            params:{
              id
            },
            query:{
              id
            }
          })
        } else {
          Toast.fail('请先登录');
          this.$router.push({
            name: 'User',
            query: {redirect: this.$route.name,isShowModal:true}
          })
        }

        // 修改vuex中的 isShowPopupShow 值为true
        // this.$store.commit("changeIsShowPopupShow", true)
      },
      //真题估分
      fraction() {

      }
    },
    components: {},
    computed: {},
  }
</script>

<style lang="less">
  .banner-index .my-swipe .swipe_item {
    top: -5rem;
  }
  .question-title .van-grid-item__content::after {
    z-index: 1;
    border-width: 0;
  }

  .question-title .van-nav-bar__content .van-nav-bar__left .van-button__text {
    font-size: 2.2rem !important;
    font-weight: 600 !important;
    color: #333333 !important;
  }

  .question-title .van-nav-bar__content .van-nav-bar__left .van-icon {
    font-size: 2.2rem !important;
    font-weight: 600;
    color: #333333;
    -moz-transform: rotate(90deg);
    -webkit-transform: rotate(90deg);
  }

  .question-title .van-nav-bar__content .van-nav-bar__right .van-icon {
    font-size: 2.8rem !important;
  }

  .question-title .van-nav-bar__content .van-nav-bar__right .van-grid-item__text {
    font-size: 1.6rem !important;
  }

  .question-title .van-nav-bar__content .van-nav-bar__right {
    padding: 0 0.5rem !important;
  }

  .question-title .van-nav-bar__content .van-nav-bar__right .van-grid-item__content {
    width: 6rem !important;
  }

  .question-content {
    padding: 6rem 0 7rem 0;
    height: 100%;
    background-color: #ffffff;
  }

  .question-navigation .van-grid {
    margin: 1rem 0;
  }

  .question-navigation .van-hairline--top::after {
    border-width: 0;
  }

  .question-navigation .van-grid-item__content {
    padding: .8rem;
  }

  .question-navigation .van-grid-item__content::after {
    border-width: 0;
  }

  .question-navigation .van-grid .van-grid-item .van-icon {
    font-size: 5rem;
  }

  .question-navigation .van-grid .van-grid-item .van-grid-item__text {
    font-size: 1.6rem;
    margin-top: .2rem;
  }


  .question-type .van-grid {
    margin: 1rem;
  }
  .question-type .van-grid .tagLabel {
    position:absolute;
    right: 0.7rem;
    top: 1rem;
    z-index: 2;
    padding: 0.1rem 0.5rem !important;
    border-top-right-radius: 1rem;
    border-bottom-left-radius: 1rem;
  }

  .question-type .van-hairline--top::after {
    border-width: 0;
  }

  .question-type .van-grid-item__content {
    padding: 1rem 0.5rem 0.4rem 0.5rem;
  }

  .question-type .van-grid-item__content::after {
    border-width: 0;
  }

  .question-type .van-grid .van-grid-item .typeList {
    background-color: #f2f7ff;
    border-radius: 1rem;
    padding: 1.5rem;
  }

  .question-type .van-grid .van-grid-item .typeList .van-icon {
    font-size: 3.6rem;
    padding-left: 0.4rem;
  }

  .question-type .van-grid .van-grid-item .typeList .van-tag {
    position: absolute;
    padding: 0.1rem 0.8rem !important;
    top: 0.1rem;
    right: 0;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 1.4rem;
  }

  .question-type .van-grid .van-grid-item .typeList .van-cell__label {
    line-height: 3rem;
    font-size: 1.6rem;
  }

  .question-product {
    margin: 1rem;
  }

  .question-product .title {
    padding: 0 1rem;
  }

  .question-product .title::after {
    border-width: 0;
  }

  .question-product .title .van-cell__title {
    font-weight: 600;
    font-size: 2.4rem;
  }

  .question-product .title .van-cell__value {
    line-height: 4rem;
    font-size: 1.8rem;
  }

  .question-product .title .van-icon {
    font-size: 2.2rem;
  }


</style>
